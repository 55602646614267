<template>
  <div class="cart" v-if="!loading">
    <b-modal hide-footer hide-header centered v-model="modalMinimum" body-class="w-[352]">
      <div class="d-flex flex-column align-items-center text-center">
        <div class="mb-1">
          <Warning />
        </div>
        <span>Total Coin anda tidak mencukupi</span>
      </div>
    </b-modal>
    <div class="d-flex justify-content-start align-items-center mb-3">
      <h3>Keranjang Coin</h3>
    </div>

    <div class="cart-list">
      <div v-if="!carts.length" class="mt-5">
        <h1 class="mx-auto d-flex justify-content-center">
          {{ $t('purchase.cart.not_found_cart') }}
        </h1>
      </div>
      <div v-if="unProcessedItems.length > 0" class="card">
        <div class="card-body d-flex justify-content-between align-items-center">
          <span>{{ $t('menu.purchase.cart.cannot_be_process')}} ({{unProcessedItems.length}})</span>
          <button style="background-color: #FEE8E8; border: #FF0000 solid 1px; color: #FF0000;" class="btn">
            {{ $t('menu.purchase.cart.delete_product')}}
          </button>
        </div>
      </div>
      <div class="card" v-for="(warehouse, index) in carts" :key="index">
        <!-- This is non mobile view -->
        <div v-if="!isMobileView">
          <div class="card-header d-flex align-items-center">
            <location-icon style="width: 46px; height: 46px; margin-bottom: 15px; margin-left: 3rem;" />
            <p class="ml-4">
              <span style="font-size: 20px;">{{ warehouse.name }}</span>
              <br />
              <span style="font-size: 16px; color: #000000;">{{ [warehouse.address.district, warehouse.address.line1,
                warehouse.address.line2].join(', ')
              }}</span>
              <br />
            </p>
          </div>
          <div v-for="({ item_available, stock_is_empty, ...item }, index) in warehouse && warehouse.cart_list"
            :key="index">
            <div class="card-body border-bottom">
              <div class="row">
                <div class="col-6 col-sm-2 col-md-2 d-flex justify-content-end">
                  <img alt="image-product" :src="item.showimg_url"
                    style="width: 75px; height: 75px; margin-left: 20px;" />
                </div>
                <div class="col-12 col-sm-10 col-md-10">
                  <!-- <h5 class="card-title "><span class="badge badge-danger">Promo</span></h5> -->
                  <p class="card-text">
                    <span v-if="!item_available" style="color: #FF0000;" class="font-weight-bold">{{ $t('menu.purchase.cart.update_stock') }}</span>
                    <span v-if="stock_is_empty" style="color: #FF0000;" class="font-weight-bold">{{ $t('menu.purchase.cart.stock_empty') }}</span>
                    <br v-if="!item_available || stock_is_empty" />
                    <span class="text-dark">{{ item.catalog_title }}</span>
                    <br />
                    <span class="text-muted mr-1">Unit : {{ item.uom }}</span>
                    <span v-if="item.quantity > item.in_stock.sellable" style="color: #FF0000;">{{ $t('menu.purchase.cart.product_left') }} : {{ item &&
                        item.in_stock && item.in_stock.sellable
                    }}</span>
                    <br />
                  </p>
                  <div class="row justify-content-between">
                    <div class="col">
                      <div class="d-flex align-items-center">
                        <WalletOutlined color="var(--biz-brand-button)"/>
                        <div class="text-warning size-harga ml-2">{{ (item.price.selling_price * item.quantity) | currency_2
                        }}</div>
                      </div>
                    </div>
                    <div class="col d-flex justify-content-between">
                      <div class="mt-1 mr-2">
                        {{ $t('purchase.cart.quantity') }}
                      </div>
                      <div class="d-flex justify-content-between align-items-center">
                        <button class="btn-cust-dec" id="increment"
                          @click.prevent="() => item.quantity > 1 && decreamentQty(warehouse.id, item.item_id)">
                          <i class="fa fa-minus"></i>
                        </button>
                        <a-input class="centered-input1"
                          :style="{ color: item.quantity > item.in_stock.sellable ? '#FF0000' : 'black' }"
                          :value="item.quantity" @input="(e) => onChangeQty(warehouse.id, item.item_id, e)"
                          @keypress="validateQty($event)" @blur="handleNullQty(warehouse.id, item.item_id, $event)" />
                        <button
                          v-bind:class="item.quantity > item.in_stock.sellable ? 'btn rounded-circle btn-secondary' : 'btn-cust'"
                          :style="{ cursor: item.quantity > item.in_stock.sellable ? '' : 'pointer' }" id="decrement"
                          :disabled="item.quantity > item.in_stock.sellable"
                          @click.prevent="() => increamentQty(warehouse.id, item.item_id)">
                          <i class="fa fa-plus"></i>
                        </button>
                      </div>
                      <div class="ml-3">
                        <button class="btn-cust-del"
                          @click.prevent="() => (warehouseIdToDeleted = warehouse.id, itemIdToDeleted = item.id, handleOpenModalDelete(true))">
                          <i class="fa fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer d-flex justify-content-between">
            <div class="total-price d-flex justify-content-start tot-price-detail">
              <span class="text-dark">{{ $t('menu.purchase.cart.total_coin') }}</span>
              <h5 class="text-warning ml-4">{{ toCurrency(total_price(warehouse.id))}} Coin</h5>
            </div>
            <div v-if="loadingCheckInventory"
              class="button-checkout button-buy-checkout-disabled align-items-center justify-content-center d-flex">
              <a-spin></a-spin>
            </div>
            <div v-else-if="disabledButton(warehouse.cart_list)"
              class="button-checkout button-buy-checkout-disabled align-items-center justify-content-center d-flex">
              <!-- <a-spin></a-spin> -->
              {{ $t('purchase.cart.checkout') }}
            </div>
            <div v-else style='background-color: var(--biz-brand-button);'
              class="button-checkout button-buy-checkout align-items-center justify-content-center d-flex"
              @click.prevent="() => checkout(warehouse)">
              <div> {{ $t('purchase.cart.checkout') }}</div>
            </div>
          </div>
        </div>
        <!-- End of non mobile  -->
        <!-- This is Mobile View Section -->
        <div v-if="isMobileView">
          <div v-for="(warehouse, index) in carts" class="mb-3 card" :key="index">
            <a-row>
              <a-col :span="24" class="cart-list-content__warehouse">
                <div class="d-flex align-items-center p-4">
                  <location-icon />
                  <span class="font-weight-bold ml-3"> {{ warehouse.name }} </span>
                </div>
                <div style="margin-left: 1.9rem">
                  {{ [warehouse.address.line1, warehouse.address.line2].join(', ') }}
                </div>
              </a-col>
            </a-row>
            <a-row class="cart-list-content__item d-flex align-items-center mt-2 border-top"
              v-for="(item, index) in warehouse && warehouse.cart_list" :key="index">
              <a-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="mb-3">
                <a-row :style="{ marginLeft: isMobileView ? '0 !important' : '1.9rem !important' }">
                  <a-col :span="7"> <img alt="image-product" :src="item.showimg_url"
                      style="width: 78px; height: 78px; margin-top: 30px;" /> </a-col>
                  <a-col :span="isMobileView ? 15 : 17">
                    <div> {{ item.catalog_title }} </div>
                    <div v-if="isMobileView" class="mt-2">
                      <span> {{ item.price.selling_price | currency }} </span>
                      <span> / </span>
                      <span> {{ item.uom }} </span>
                    </div>
                  </a-col>
                  <!-- Not Mobile View Measurements / Unit -->
                  <a-col v-if="!isMobileView" :xs="12" :sm="12" :md="2" :lg="2" :xl="2"
                    class="d-flex justify-content-center text-center mb-2">
                    <div class="">
                      {{ item.uom }}
                    </div>
                  </a-col>
                  <a-col v-if="isMobileView" :span="2">
                    <div style="text-align: center; cursor: pointer"
                      @click.prevent="() => (warehouseIdToDeleted = warehouse.id, itemIdToDeleted = item.id, handleOpenModalDelete(true))">
                      <TrashIcon :width="'13'" :height="'17'" />
                    </div>
                  </a-col>
                </a-row>
                <a-row v-if="isMobileView" class="mt-3">
                  <a-col :span="12">
                    {{ $t('purchase.cart.quantity') }}
                  </a-col>

                  <a-col :span="12" class="d-flex justify-content-center text-center qty-column">
                    <div class="qty d-flex justify-content-between align-items-center p-2 ">
                      <div style="cursor: pointer" @click.prevent="() => decreamentQty(warehouse.id, item.item_id)"> -
                      </div>
                      <div>
                        <a-input class="centered-input1" :value="item.quantity"
                          @input="(e) => onChangeQty(warehouse.id, item.item_id, e)" @keypress="validateQty($event)"
                          @blur="handleNullQty(warehouse.id, item.item_id, $event)" />
                      </div>
                      <div style="cursor: pointer" @click.prevent="() => increamentQty(warehouse.id, item.item_id)"> +
                      </div>
                    </div>
                    <div class="required-qty" v-if="item.showDisclaimerQty">{{ $t('purchase.catalogue.qty_req') }}1
                    </div>
                  </a-col>
                </a-row>

                <a-row v-if="isMobileView" v-bind:class="item.showDisclaimerQty ? 'mt-4' : 'mt-3'">
                  <a-col :span="12">
                    {{ $t('purchase.cart.total') }}
                  </a-col>

                  <a-col :span="12" class="d-flex justify-content-center text-center">
                    <div> {{ (item.price.selling_price * item.quantity) | currency }} </div>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
            <a-row class="mt-2">
              <a-col :span="24"
                class="cart-list-content__calculation d-flex justify-content-between align-items-center">
                <div class="total-price">
                  {{ total_price(warehouse.id) | currency }}
                </div>
                <div v-if="loadingCheckInventory" style="cursor: pointer; background-color: #E5E5E5;"
                  class="button-checkout" @click.prevent="() => checkout(warehouse)">
                  <a-spin></a-spin>
                </div>
                <div v-else style='background-color: var(--biz-brand-button);' class="button-checkout"
                  @click.prevent="() => checkout(warehouse)">
                  <div> {{ $t('purchase.cart.checkout') }}</div>
                </div>
              </a-col>
            </a-row>
          </div>
        </div>
      </div>
    </div>
    <ModalDelete :isLoyalty="true" :visible="visibleModalDelete" @fetchCarts="fetchCarts" :cart_id="cartTemp.id"
      @handleOpenModalDelete="handleOpenModalDelete" cart_i :cart_item_id="itemIdToDeleted"
      :warehouse_id="warehouseIdToDeleted" />
  </div>
</template>

<script>
import LocationIcon from '@/components/Icons/Location.vue'
import TrashIcon from '@/components/Icons/Trash.vue'
import ModalDelete from '@/components/Purchase/ModalDelete.vue'
import Warning from '@/components/Icons/Warning.vue'
import { getIdWarehouse, joinWarehouseCart } from '@/utils/purchase'
import debounce from 'lodash/debounce'
import WalletOutlined from '@/components/Icons/WalletOutlined.vue'

export default {
  data: function () {
    return {
      carts: [],
      cartTemp: [],
      visibleModalDelete: false,
      itemIdToDeleted: null,
      warehouseIdToDeleted: null,
      loading: false,
      modalMinimum: false,
      quantityToUpdated: null,
      cartIdToUpdated: null,
      valueIncreamentQty: [],
      valueDecreamentQty: [],
      disabledCheckoutBtn: false,
      modalVisible: false,
      minimumTransaction: 0,
      loadingCheckInventory: false,
      unProcessedItems: [],
    }
  },
  components: {
    LocationIcon,
    TrashIcon,
    ModalDelete,
    Warning,
    WalletOutlined,
  },
  computed: {
    isMobileView() {
      return this.$store.state.settings.isMobileView
    },
  },
  methods: {
    disabledButton(cart_list) {
      const isThereEmptyCart = cart_list.some(({ stock_is_empty }) => stock_is_empty)
      const isOverQuantity = cart_list.some(({ quantity, in_stock }) => quantity > in_stock.sellable)
      return isThereEmptyCart || isOverQuantity
    },
    checkout(warehouse) {
      const total = this.total_price(warehouse && warehouse.id)
      if (total > this.$store.state.loyalty.totalKinoCoin.available_point) {
        this.modalMinimum = true
      } else {
        this.$router.push({ path: '/loyalty-redeem/checkout', query: { warehouse_id: warehouse.id } })
      }
    },
    async getMinimumTransaction() {
      this.disabledCheckoutBtn = true
      this.$store.dispatch('meta/MINIMUM_TRANSACTION').then(res => {
        this.minimumTransaction = res.meta_value
        this.disabledCheckoutBtn = false
      }).catch(err => {
        console.log(err)
      })
    },
    fetchCarts() {
      this.$store
        .dispatch('purchase/GETCARTLOYALTY', {
          channel_id: this.$store.state.app.redeem_channel_id,
        })
        .then(async ({ data }) => {
          if (data) {
            const newData = Promise.all(data.cart_list.map(async ({ item_id, warehouse_id, uom, ...props }) => {
              const checkInventory = await this.getInventory(item_id, warehouse_id, uom)
              if (checkInventory?.data?.length > 0) {
                return {
                  ...props,
                  item_id,
                  warehouse_id,
                  uom,
                  item_available: props.quantity <= checkInventory.data[0].sellable,
                  stock_is_empty: checkInventory.data[0].sellable === 0,
                  in_stock: {
                    ...checkInventory.data[0],
                  },
                }
              } else {
                return {
                  ...props,
                  item_id,
                  warehouse_id,
                  uom,
                }
              }
            }))
            const newCartList = {
              ...data,
              cart_list: await newData,
            }
            this.cartTemp = { ...newCartList }
            const warehouse_ids = getIdWarehouse(this.cartTemp.cart_list)
            return this.$store.dispatch('warehouse/GETWAREHOUSELIST', {
              id: warehouse_ids,
            })
          }
        })
        .then(({ data }) => {
          const cart = joinWarehouseCart(data, this.cartTemp.cart_list)
          this.unProcessedItems = cart.filter(({ cart_list }) => cart_list.some(({ stock_is_empty }) => stock_is_empty))
          this.carts = cart
        })
        .catch((err) => {
          console.log({ err })
          this.carts = []
        })
        .finally(() => {
          this.loading = false
        })
    },
    async getInventory(catalog_id, warehouse_id, units) {
      return this.$store.dispatch('warehouse/CHECKINVENTORY', {
        channel_id: this.$store.state.app.redeem_channel_id,
        catalog_id,
        warehouse_id,
        units,
      })
    },
    handleOpenModalDelete(param) {
      this.visibleModalDelete = param
    },
    handleSelectPayment(value) {
      this.modalVisible = true
    },
    total_price(warehouse_id) {
      const { cart_list } = this.carts.find((item) => item.id === warehouse_id)

      const price = cart_list.reduce((acc, current) => {
        return acc + current.quantity * current.price.selling_price
      }, 0)

      return price || 0
    },
    discount_price(warehouse_id, item_id) {
      const findDataByWarehouse = this.carts.find(
        (item) => item.warehouse_id === warehouse_id,
      )
      const findItemData = findDataByWarehouse.cart_list.find(
        (item) => item.id === item_id,
      )

      switch (findItemData.price.discount_type) {
        case 1: {
          return `${findItemData.price.discount_value}% OFF`
        }

        default:
          return null
      }
    },
    increamentQty(warehouse_id, item_id) {
      this.loadingCheckInventory = true
      this.carts = this.carts.map((item) => {
        if (item.id === warehouse_id) {
          const cart_list = item.cart_list.map((value) => {
            if (
              value.item_id === item_id &&
              (value.quantity + 1).toString().length <= 4
            ) {
              value.quantity++
              this.quantityToUpdated = value.quantity
              this.cartIdToUpdated = value.id
              return value
            } else {
              return value
            }
          })

          return {
            ...item,
            cart_list,
          }
        } else {
          return item
        }
      })
      this.updateQty()
    },
    debounceQty: debounce(function () {
      this.quantityToUpdated && this.updateQty()
    }, 1000),
    onChangeQty(warehouse_id, item_id, params) {
      this.carts = this.carts.map((item) => {
        if (item.id === warehouse_id) {
          const cart_list = item.cart_list.map((value) => {
            if (value.item_id === item_id) {
              if (String(params.target.value).length <= 4) {
                if (params.target.value === '' || params.target.value === '0') {
                  if (params.type === 'blur') {
                    value.showDisclaimerQty = false
                    value.quantity = 1
                    this.quantityToUpdated = value.quantity
                    this.cartIdToUpdated = value.id
                  } else {
                    value.showDisclaimerQty = true
                    value.quantity = ''
                    this.quantityToUpdated = value.quantity
                    this.cartIdToUpdated = value.id
                  }
                } else {
                  value.showDisclaimerQty = false
                  value.quantity = parseInt(params.target.value)
                  this.quantityToUpdated = value.quantity
                  this.cartIdToUpdated = value.id
                }
              }
              this.$forceUpdate()
              return value
            } else {
              return value
            }
          })

          return {
            ...item,
            cart_list,
          }
        } else {
          return item
        }
      })

      if (this.quantityToUpdated !== '') {
        this.updateQty()
        this.disabledCheckoutBtn = false
      } else {
        this.disabledCheckoutBtn = true
      }
    },
    validateQty(event) {
      const regex = new RegExp(/^[\d]*$/g)
      if (!regex.test(event.key)) {
        event.preventDefault()
      }
    },
    handleNullQty(warehouse_id, item_id, event) {
      if (event.target.value === '0' || event.target.value === '') {
        this.onChangeQty(warehouse_id, item_id, event)
      }
    },
    decreamentQty(warehouse_id, item_id) {
      this.loadingCheckInventory = true
      this.carts = this.carts.map((item) => {
        if (item.id === warehouse_id) {
          const cart_list = item.cart_list.map((value) => {
            if (value.item_id === item_id && value.quantity > 1) {
              value.quantity--
              this.quantityToUpdated = value.quantity
              this.cartIdToUpdated = value.id
              return value
            } else {
              return value
            }
          })

          return {
            ...item,
            cart_list,
          }
        } else {
          return item
        }
      })
      this.updateQty()
    },
    updateQty() {
      this.$store
        .dispatch('purchase/UPDATECART', {
          cart_item_id: this.cartIdToUpdated,
          new_qty: this.quantityToUpdated,
          channel_id: this.$store.state.app.redeem_channel_id,
          cart_id: this.cartTemp.id,
        })
        .then(() => {
          this.cartIdToUpdated = null
          this.quantityToUpdated = null
          this.fetchCarts()
          this.loadingCheckInventory = false
        })
        .catch((err) => {
          const { data } = err.response
          this.$notification.error({
            message: this.$t('purchase.cart.failed'),
            description:
              data && data.message ? data.message : 'Internal Server Error',
          })
          this.loadingCheckInventory = false
        })
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('id-ID').format(value || 0)}`
    },
  },
  mounted() {
    this.fetchCarts()
    this.getMinimumTransaction()
  },
}
</script>

<style lang="scss">
.cart {
  &-icon {
    background: #fff !important;
    padding: 0px 4px;
    border-radius: 50%;
  }

  &-list {
    &__row {
      padding: 0.5rem 0 !important;
      background: #d9d9d9 !important;
      padding: 1rem !important;
    }

    &__col {
      text-align: center;
    }

    &-content {
      &__warehouse {
        height: 70px;
        background: #ffffff;
        padding: 1rem !important;
      }

      &__item {
        background: #ffffff;
        height: 113px !important;
        padding: 1rem !important;

        @media (max-width: 768px) {
          height: auto !important;
        }

        .qty {
          width: 100px;
          height: 27px;
          left: 915px;
          top: 376px;

          border: 0.5px solid #585858;
          box-sizing: border-box;
        }

        .discount {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #ff0000;
        }
      }

      &__calculation {
        height: 70px;
        background: #ffffff;
        padding: 1rem !important;

        .total-price {
          margin-left: 1.9rem;
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          color: #000000;
        }

        .button-checkout {
          margin-right: 1.9rem !important;
          background: var(--biz-brand-button);
          border-radius: 5px;
          color: #ffffff;
          width: 100px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.tot-price-detail {
  margin-left: 5rem;
  margin-top: 10px;
  font-weight: bolder;
}

.button-buy-checkout {
  width: 160px;
  background-color: var(--biz-brand-button);
  color: #ffffff;
  border-radius: 8px;
  margin-right: 5rem;
  margin-left: 20px;
  cursor: pointer;
}

.button-buy-checkout-disabled {
  width: 160px;
  background-color: #E5E5E5;
  color: #ffffff;
  border-radius: 8px;
  margin-right: 5rem;
  margin-left: 20px;
  cursor: not-allowed!important;
}

.btn-cust {
  background-color: var(--biz-brand-button);
  border: none;
  color: white;
  padding: 8px 12px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 55px;
  &:hover {
    background-color: #ffffff;
    color: var(--biz-brand-button);
  }
}

.btn-cust-dec {
  background-color: #ffffff;
  border-width: thin;
  border-color: var(--biz-brand-button);
  color: var(--biz-brand-button);
  padding: 6px 10px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 55px;
}

.btn-cust-del {
  background-color: #f4f4f4;
  border: none;
  color: #ababab;
  padding: 8px 12px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 55px;
}

/* Darker background on mouse-over */
.btn-cust-del:hover {
  background-color: #e2e2e2;
}

.btn-cust-dec:hover {
  background-color: #e2e2e2;
}

/* Darker background on mouse-over */
.btn-cust:hover {
  background-color: #e2e2e2;
}

.button-cust-count {
  padding: 8px 12px;
  background-color: var(--biz-brand-button);
  border-radius: 4px;
  color: white;
  font-size: 6px;
  font-weight: bold;
}

.vl {
  border-right: 1px solid var(--biz-brand-button);
  height: 27px;
}

.centered-input1 {
  text-align: center;
  border: 0px !important;
  height: 25px !important;
}

.required-qty {
  text-align: center;
  font-size: 12px;
  color: #ff0000;
  margin-top: 2px;
  width: 100%;
  position: absolute;
  top: 27px;
}

.qty-column {
  flex-direction: column;
  display: flex;
  align-items: center;
}

.size-harga {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
}
</style>
